import DynamicRoute from '@jetshop/core/components/DynamicRoute';
import { GlobalFilters } from '@jetshop/core/components/Filters/GlobalFilters';
import PaginationProvider from '@jetshop/core/components/Pagination/PaginationProvider';
import { useShopConfig } from '@jetshop/core/hooks/useShopConfig';
import { AddwishProvider } from '@jetshop/flight-addwish';
import GenericError from '@jetshop/ui/ErrorBoundary/Generic';
import ModalProvider from '@jetshop/ui/Modal/ModalProvider';
import ModalRoot from '@jetshop/ui/Modal/ModalRoot';
import ScrollRestorationHandler from '@jetshop/ui/ScrollRestorationHandler';
import loadable from '@loadable/component';
import React from 'react';
import Helmet from 'react-helmet-async';
import { Route, Switch } from 'react-router-dom';
import Container from './Layout/Container';
import Content from './Layout/Content';
import Footer from './Layout/Footer/Footer';
import Header from './Layout/Header/Header';
import LoadingPage from './LoadingPage';
import { ModelSelectorProvider } from './ModelSelector/ModelSelectorProvider';
import NotFound from './NotFoundPage';
import { VatProvider } from './Price/VatContext';
import { productListQueries } from './ProductList/productListQueries';
import routeQuery from './RouteQuery.gql';
import Theme from './Theme';
import loadFontCss from '../fonts/loadFontCss';
import CustomFont from '@jetshop/core/components/Fonts/CustomFont';

import { useChannel } from '../hooks/useChannel';

import eyewearstoreIcon  from '../img/eyewearstore-faviconfav.ico';
import skoterdelenIcon  from '../img/favicon.ico';
import { ProductListProvider } from './ProductList/hooks/ProductListContext';

const LoadableStartPage = loadable(() => import('./StartPage/StartPage'), {
  fallback: <LoadingPage />,
});

const LoadableProductPage = loadable(
  () => import('./ProductPage/ProductPage'),
  {
    fallback: <LoadingPage />,
  }
);

const LoadableCategoryPage = loadable(
  () => import('./CategoryPage/CategoryPage'),
  {
    fallback: <LoadingPage />,
  }
);

const LoadableContentPage = loadable(
  () => import('./ContentPage/ContentPage'),
  {
    fallback: <LoadingPage />,
  }
);

const LoadableSearchPage = loadable(() => import('./SearchPage/SearchPage'), {
  fallback: <LoadingPage />,
});

const LoadableSignUpPage = loadable(() => import('./Auth/Signup/SignUpPage'), {
  fallback: <LoadingPage />,
});

const ForgotPassword = loadable(() => import('./Auth/ForgotPassword'), {
  fallback: <LoadingPage />,
});

const ResetPassword = loadable(() => import('./Auth/ResetPassword'), {
  fallback: <LoadingPage />,
});
const LoadableMyPages = loadable(() => import('./MyPages/MyPages'), {
  fallback: <LoadingPage />,
});

const LoadableLogInPages = loadable(() => import('./Auth/LogInPage'), {
  fallback: <LoadingPage />,
});

function saveFilters({ multiListFilters }) {
  if (
    typeof window !== 'undefined' &&
    typeof window.localStorage !== 'undefined'
  ) {
    if (multiListFilters.length > 0) {
      localStorage.setItem(
        'savedFilter',
        JSON.stringify([multiListFilters[multiListFilters.length - 1]])
      );
    }
  }
}

function getFilters() {
  if (
    typeof window !== 'undefined' &&
    typeof window.localStorage !== 'undefined'
  ) {
    const filtersInUrl =
      window.location.search.indexOf('skoter-guide') > 0 ||
      window.location.search.indexOf('atv-guide') > 0 ||
      window.location.search.indexOf('MC-Guiden') > 0;
      
    var savedFilter = localStorage.getItem('savedFilter');

    if (
      filtersInUrl ||
      !savedFilter ||
      JSON.parse(savedFilter)[0].values.length < 3
    ) {
      return [];
    }
    return JSON.parse(savedFilter);
  }
  return [];
}
var savedFilter = {
  listFilters: [],
  rangeFilters: [],
  booleanFilters: [],
  multiListFilters: getFilters(),
};

function Shop() {
  const { routes } = useShopConfig();
  const { isWeAhl } = useChannel();
  const storeName = isWeAhl ? 'Eyewearstore' : 'Skoterdelen'
  return (
    <GenericError>
      <ModalProvider>
        <Theme>
          <Container data-channel={storeName}>
            <CustomFont primaryFont={'Abel'} injectCss={loadFontCss} />
            <PaginationProvider defaultProductsPerPage={52}>
              <VatProvider>
                <AddwishProvider>
                  <Helmet titleTemplate="%s" defaultTitle={storeName}>
                    <link
                      rel="icon"
                      type="image/png"
                      href={`${
                        isWeAhl ? eyewearstoreIcon : skoterdelenIcon
                      }?v=2`}
                      sizes="16x16"
                    />
                  </Helmet>
                  <ProductListProvider queries={productListQueries}>
                    <GlobalFilters
                      disableParamChange={true}
                      onFilterChange={saveFilters}
                      defaultFilters={savedFilter}
                      persistFiltersById={['skoter-guide', 'atv-guide', 'MC-Guiden']}
                    >
                      <ModelSelectorProvider>
                        <Header isWeAhl={isWeAhl} />
                        <Content>
                          <Switch>
                            <Route
                              exact
                              path="/"
                              component={LoadableStartPage}
                            />
                            <Route
                              path="/search"
                              component={LoadableSearchPage}
                            />
                            <Route
                              path={routes.login}
                              component={LoadableLogInPages}
                            />
                            <Route
                              path={routes.signup}
                              component={LoadableSignUpPage}
                            />
                            <Route
                              path={routes.myPages}
                              component={LoadableMyPages}
                            />
                            <Route
                              exact
                              path="/forgot-password"
                              component={ForgotPassword}
                            />
                            <Route
                              path="/reset-password/:token"
                              component={ResetPassword}
                            />
                            <DynamicRoute
                              routeQuery={routeQuery}
                              productPage={LoadableProductPage}
                              categoryPage={LoadableCategoryPage}
                              contentPage={LoadableContentPage}
                              notFoundPage={NotFound}
                              LoadingPage={LoadingPage}
                            />
                          </Switch>
                        </Content>
                      </ModelSelectorProvider>
                    </GlobalFilters>
                  </ProductListProvider>
                  <Footer />
                  <ModalRoot />
                  <ScrollRestorationHandler
                    ignoreForRouteTypes={['sortOrderChange', 'filterChange']}
                  />
                </AddwishProvider>
              </VatProvider>
            </PaginationProvider>
          </Container>
        </Theme>
      </ModalProvider>
    </GenericError>
  );
}

export default Shop;

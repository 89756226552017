import React, { Fragment } from 'react';
//import CartProvider from '@jetshop/core/components/Query/CartProvider';
import styled from 'react-emotion';
import theme from '@jetshop/ui/utils/theme';
import { FlyoutTrigger } from '@jetshop/ui/Modal/Flyout';
import { DrawerTrigger } from '@jetshop/ui/Modal/Drawer/DrawerTrigger';
import { Above } from '@jetshop/ui/Breakpoints';
import { useProductList } from './hooks/useProductList';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingCart, faHeart } from '@fortawesome/pro-light-svg-icons';
library.add(faShoppingCart, faHeart);

const Button = styled('button')`
  padding: 0;
  background: transparent;
  color: inherit;
  border: 0;
  outline: none;
  margin-left: 20px;
  transition: all, 0.2s;
  ${theme('below.md')} {
    color: white;
    margin: 0;
    padding: 10px;
    position: relative;
    margin-top: 4px;
  }
  :hover {
    color: ${theme('colors.red')};
  }
  svg {
    margin-right: 10px;
    font-size: 2rem;
    color: black;
    [data-channel='Skoterdelen'] & {
      color: white;
    }
    ${theme('below.md')} {
      margin: 0;
      font-size: 1.5rem;
    }
  }
  div {
    display: inline-block;
    span {
      display: block;
      margin: 0;
      text-align: left;
      &:first-child {
        font-size: 24px;
      }
      &:last-child {
        font-size: 14px;
      }
    }
  }
`;

const Holder = styled('div')`
  position: relative;
`;

const Badge = styled('span')`
  background: ${theme('colors.red')};
  color: #fff;
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  border-radius: 20px;
  position: absolute;
  top: -5px;
  right: -5px;
  font-size: 12px;
  ${theme('below.md')} {
    width: 15px;
    height: 15px;
    line-height: 15px;
    top: -7px;
    right: -10px;
    left: auto;
    font-size: 12px;
  }
`;

const FavoritesButton = () => {
  const { count } = useProductList();
  return (
    <Above breakpoint="md">
      {(matches) =>
        matches ? (
          <Holder>
            <FlyoutTrigger id="favorites-flyout">
              {(flyout) => (
                <Button
                  onClick={
                    flyout.isOpen ? flyout.hideTarget : flyout.showTarget
                  }
                >
                  <Fragment>
                    <FontAwesomeIcon icon={['fal', 'heart']} />
                  </Fragment>
                </Button>
              )}
            </FlyoutTrigger>
            {count > 0 && <Badge>{count}</Badge>}
          </Holder>
        ) : (
          <React.Fragment>
            <DrawerTrigger id="favorites-drawer">
              {(drawer) => (
                <Button
                  onClick={
                    drawer.isOpen ? drawer.hideTarget : drawer.showTarget
                  }
                >
                  <Fragment>
                    <FontAwesomeIcon icon={['fal', 'heart']} />
                    {count > 0 && <Badge>{count}</Badge>}
                  </Fragment>
                </Button>
              )}
            </DrawerTrigger>
          </React.Fragment>
        )
      }
    </Above>
  );
};

export default FavoritesButton;


/**
 * Takes a ProductListProduct and checks whether it is possible to add it to cart
 */
export function validateProduct(product) {
  if (
    (product.variant && !product.variant.stockStatus.buyable) ||
    !product.stockStatus.buyable
  ) {
    return {
      status: 'outOfStock',
      message: product.stockStatus.text
    };
  }
  if (product.isPackage) {
    return {
      status: 'package',
      message: 'Product is a package'
    };
  }
  if (product.isPreOrder) {
    return {
      status: 'preOrder',
      message: 'Pre-order date required'
    };
  }
  if (product.hasConfigurations) {
    return {
      status: 'configurations',
      message: 'Product has configurations'
    };
  }
  if (product.hasVariants && !product.variant) {
    return {
      status: 'missingVariant',
      message: 'Product has no selected variant'
    };
  }
  return {
    status: 'valid',
    message: null
  };
}
import {
  useProductList,
  useProductListItems
} from './hooks';
import { useAddMultipleToCart } from '@jetshop/core/hooks/useAddMultipleToCart';
import t from '@jetshop/intl';
import React, { useState } from 'react';
import styled from 'react-emotion';
import addMultipleToCartMutation from '../Cart/addMultipleToCart.gql';
import cartQuery from '../Cart/CartQuery.gql';
import theme from '@jetshop/ui/utils/theme';
import Button from '../ui/Button';
import { Product } from './Product';

import { Above } from '@jetshop/ui/Breakpoints';
import { MenuTitle, CloseIcon } from '../Layout/Header/Header';
import Drawer, { DrawerTarget } from '@jetshop/ui/Modal/Drawer';
import { FlyoutTarget } from '@jetshop/ui/Modal/Flyout';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faHeart,
  faShoppingCart,
  faTimes
} from '@fortawesome/pro-light-svg-icons';

import ProductLink from '@jetshop/ui/ProductLink'


library.add(faHeart, faShoppingCart, faTimes);

const Flyout = styled('div')`
  background: white;
  color: ${theme('colors.black')};
  max-height: 75vh;
  overflow-y: scroll;
  ${({ theme }) => theme.above.md} {
    position: absolute;
    z-index: 999;
    right: 10%;
    top: 100%;
    width: 22rem;
    min-width: 40rem;
    max-width: 100%;
    padding: 1rem;
  }
  ${theme('below.md')} {
    padding: 20px;
  }
`;

const Header = styled('header')`
  position: relative;
  text-align: left;
  border-bottom: 1px solid #e8e8e8;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 0 0 1rem 0;
  margin: 0 0 1rem 0;
  h2 {
    font-size: 2rem;
    text-transform: uppercase;
    ${theme('below.md')} {
      font-size: 1.5rem;
    }
  }
`;

const Empty = styled.div`
  margin: 1rem auto;
  h2 {
    font-size: 1.2rem;
  }
  p {

  }
`

const FavoriteHeaderIcon = styled(FontAwesomeIcon)`
  font-size: 2rem;
  margin-right: 10px;
  ${theme('below.md')} {
    font-size: 1.5rem;
  }
`;

const FavoriteItemList = styled('div')``;

const EmptyButton = styled('div')`
  color: #a5a5a5;
  margin: 1rem 0;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  span {
    font-size: 1.2rem;
  }
`;
const EmptyButtonIcon = styled(FontAwesomeIcon)`
  font-size: 2rem;
  margin-right: 10px;
  color: #a5a5a5;
`;

const AddToCartButton = styled(Button)`
  width: 100%;
  height: 54px;
  line-height: 54px;
  text-align: center;
  padding: 0;
  margin: 0;
  text-transform: uppercase;
  font-size: 1.2rem;
  background: ${theme('colors.green')};
  color: white;
  font-family: 'Abel', sans-serif;
  margin-bottom: 1rem;
  ${theme('below.md')} {
    font-size: 1rem;
  }

  &:disabled {
    opacity: 0.5;
  }
`;

const Failed = styled('div')`
  strong {
    display: block;
    margin-bottom: 0.5rem;
  }
  li {
    margin-bottom: 0.5rem;
  }
  margin-bottom: 1.5rem;
`;

const CloseModal = styled('div')`
  position: absolute;
  top: 0;
  right: 0;
`;

export const FavoritesFlyout = props => (
  // Wrap the flyout with the needed providers
  <Above breakpoint="md">
    {matches => matches ? (
      <FlyoutTarget id="favorites-flyout">
        {flyout => <Favourites modal={flyout} {...props} />}
      </FlyoutTarget>
    ) : (
        <DrawerTarget id="favorites-drawer">
          {drawer => (
            <Drawer isOpen={drawer.isOpen} right>
              <MenuTitle onClick={drawer.hideTarget}>
                <CloseIcon icon={['fal', 'times']} /> {t('Close')}
              </MenuTitle>
              <Favourites modal={drawer} {...props} />
            </Drawer>
          )}
        </DrawerTarget>
      )}
  </Above>
);

export function Favourites({ modal }) {
  const { products, loading } = useProductListItems();
  const [showOnMutation, setShowOnMutation] = useState(false)

  const validItems = getItemsForAddToCart(products);
  const invalidItems = getInvalidProductsForAddToCart(products)

  const [addToCart, { loading: mutationLoading }] = useAddMultipleToCart(
    validItems,
    {
      addMultipleToCartMutation,
      cartQuery
    },
    {
      onCompleted: () => setShowOnMutation(true),
    }
  );

  const noProductsInList = products.length === 0 && !loading;
  const loadingInitialRender = products.length === 0 && loading;

  if (noProductsInList) {
    return (
      <Flyout>
        <Header>
          <FavoriteHeaderIcon icon={['fal', 'heart']} />
          <h2>Dina favoriter</h2>

          <CloseModal onClick={modal.hideTarget}>
            <EmptyButtonIcon icon={['fal', 'times']} />{' '}
          </CloseModal>
        </Header>
        <Empty>
          <h2>{t('Favoriter')}</h2>
          <p>{t('Inga sparade favoriter')}</p>
        </Empty>
        <AddToCartButton onClick={addToCart} disabled>
          {mutationLoading ? t('Lägger i varukorgen...') : t('Lägg alla favoriter i varukorgen')}
        </AddToCartButton>
      </Flyout>
    );
  }

  if (loadingInitialRender) {
    return (
      <Flyout>
        <Header>
          <FavoriteHeaderIcon icon={['fal', 'heart']} />
          <h2>Dina favoriter</h2>

          <CloseModal onClick={modal.hideTarget}>
            <EmptyButtonIcon icon={['fal', 'times']} />{' '}
          </CloseModal>
        </Header>
        <Empty>
          <h2>{t('Favoriter')}</h2>
          <p>{t('Laddar sparade favoriter…')}</p>
        </Empty>
        <AddToCartButton onClick={addToCart} disabled>
          {mutationLoading ? t('Lägger i varukorgen...') : t('Lägg alla favoriter i varukorgen')}
        </AddToCartButton>
      </Flyout>
    );
  }

  return (
    <Flyout>
      <Header>
        <FavoriteHeaderIcon icon={['fal', 'heart']} />
        <h2>Dina favoriter</h2>

        <CloseModal onClick={modal.hideTarget}>
          <EmptyButtonIcon icon={['fal', 'times']} />{' '}
        </CloseModal>
      </Header>

      <FavoriteItemList>
        {products.map(product => (
          <Product key={product.id} product={product} {...modal} />
        ))}
      </FavoriteItemList>

      <ClearList modal={modal}>
        <EmptyButtonIcon icon={['fal', 'times']} />{' '}
        <span>{t('Töm favoriter')}</span>
      </ClearList>

      {invalidItems.length > 0 && showOnMutation && (
        <Failed>
          <strong>Kunde inte lägga till följande produkter:</strong>
          <ul>
            {invalidItems.map((item) => (
              <li key={item.id}>
                <ProductLink onClick={modal.hideTarget} product={item}>
                  {item.name}
                </ProductLink>
                {' - '}
                <ProductLink onClick={modal.hideTarget} product={item}>
                  {t('Gå till produkt och välj attribut')}
                </ProductLink>
              </li>
            ))}
          </ul>
        </Failed>
      )}
      <AddToCartButton onClick={addToCart}>
        {mutationLoading ? t('Lägger i varukorgen...') : t('Lägg alla favoriter i varukorgen')}
      </AddToCartButton>
    </Flyout>
  );
}

function ClearList({ children, modal }) {
  const { clear } = useProductList();
  return (
    <EmptyButton onClick={() => {
      clear()
      modal.hideTarget()
    }}
    >
      {children}
    </EmptyButton>
  );
}

function getItemsForAddToCart(products) {
  // When adding to cart we only want valid items, and each articleNumber needs
  // to be the variant rather than the base product
  return products
    .filter(product => {
      return product.validation.status === 'valid';
    })
    .map(product => ({
      ...product,
      articleNumber: product.isVariant
        ? product.variant.articleNumber
        : product.articleNumber
    }));
}

function getInvalidProductsForAddToCart(products) {
  return products.filter(product => product.validation.status === 'missingVariant')
}
import produce from 'immer';

export function reducer(state, action) {
  switch (action.type) {
    case 'LOGIN':
      return produce(state, draft => {
        draft.loggedIn = true;
        draft.list = action.payload;
      });
    case 'LOGOUT': {
      return produce(state, draft => {
        draft.loggedIn = false;
        draft.list = {};
      });
    }
    case 'UPDATE': {
      const {
        articleNumber,
        variantArticleNumber,
        options,
        variantToReplace
      } = action.payload;
      return replaceItemInList(state, articleNumber, {
        variantArticleNumber,
        variantToReplace,
        options
      });
    }
    case 'ADD': {
      const { variantArticleNumber, articleNumber, options } = action.payload;
      if (variantArticleNumber) {
        return addVariantToList(state, articleNumber, {
          variantArticleNumber,
          options
        });
      } else {
        return addBaseProductToList(state, articleNumber, options);
      }
    }
    case 'REMOVE': {
      const { variantArticleNumber, articleNumber } = action.payload;
      const list = removeFromList(state, articleNumber, {
        variantArticleNumber
      });
      return list;
    }
    case 'REPLACE': {
      return produce(state, draft => {
        draft.list = action.payload;
      });
    }
    default:
      throw new Error(`Unsupported action ${action} for product list reducer`);
  }
}

export function init(initialState) {
  if (typeof window === 'undefined') return initialState;

  const updatedState = {
    list:
      JSON.parse(window.localStorage.getItem('productList')) ||
      initialState.list ||
      null,
    loggedIn: initialState.loggedIn,
    requestIdRef: initialState.requestIdRef
  };

  return updatedState;
}

export function addVariantToList(state, articleNumber, { variantArticleNumber, options }) {
  return produce(state, draft => {
    draft.list[articleNumber] = {
      options: draft.list[articleNumber]?.options || null,
      variants: produce(draft.list[articleNumber]?.variants || {}, draft => {
        draft[variantArticleNumber] = {
          options,
          parentArticleNumber: articleNumber
        };
      })
    };
  });
}

export function addBaseProductToList(state, articleNumber, options) {
  return produce(state, draft => {
    draft.list[articleNumber] = {
      variants: draft.list[articleNumber]?.variants || null,
      options
    };
  });
}

export function removeFromList(state, articleNumber, { variantArticleNumber }) {
  if (variantArticleNumber) {
    return produce(state, draft => {
      delete draft.list[articleNumber].variants[variantArticleNumber];

      if (
        Object.keys(draft.list[articleNumber].variants).length === 0 &&
        draft.list[articleNumber].options === null
      ) {
        delete draft.list[articleNumber];
      }
    });
  } else {
    return produce(state, draft => {
      if (draft.list[articleNumber].variants) {
        draft.list[articleNumber].options = null;
      } else {
        delete draft.list[articleNumber];
      }
    });
  }
}

export function replaceItemInList(state, articleNumber, { variantToReplace, variantArticleNumber, options }) {
  if (variantToReplace) {
    return produce(state, draft => {
      const variants = draft.list[articleNumber].variants;

      delete variants[variantToReplace];

      draft.list[articleNumber].variants = {
        ...variants,
        [variantArticleNumber]: {
          options,
          parentArticleNumber: articleNumber
        }
      };
    });
  } else {
    return produce(state, draft => {
      draft.list[articleNumber] = {
        options: null,
        variants: {
          ...draft.list[articleNumber]?.variants,
          [variantArticleNumber]: {
            options,
            parentArticleNumber: articleNumber
          }
        }
      };
    });
  }
}
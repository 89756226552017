import { flattenList, normalizeServerList } from './list-transforms';
import uniqueId from '@jetshop/core/helpers/uniqueId';

export function loginAction({ loginMutation }) {
  return (dispatch, getState) => {
    const state = getState();
    loginMutation({
      fetchPolicy: 'no-cache',
      context: { useNetworkStatusNotifier: false },
      variables: {
        id: null,
        items: flattenList(state.list).map(listItem => ({
          articleNumber: listItem.articleNumber,
          quantity: listItem.options.quantity,
          description: listItem.options.description
        }))
      }
    })
    .then(response => {
      const list = normalizeServerList(response.data.addToCustomerProductList);
      dispatch({ type: 'LOGIN', payload: list });
    })
    .catch(err => {
      console.error('Lists could not be merged', err);
    });
  };
}

export function refreshAction() {
  return dispatch => {
    dispatch({
      type: 'REPLACE',
      payload: JSON.parse(localStorage.getItem('productList'))
    });
  };
}

export function updateAction({
                               updateAPI = false,
                               removeMutation,
                               addMutation,
                               options,
                               articleNumber,
                               variantToReplace,
                               variantArticleNumber
                             }) {
  return (dispatch, getState) => {
    const { description, quantity } = options;
    dispatch({
      type: 'UPDATE',
      payload: {
        articleNumber,
        options,
        variantArticleNumber,
        variantToReplace
      }
    });

    if (!updateAPI) return;

    const [remove] = removeMutation;
    const [add] = addMutation;
    const previousState = getState();

    const requestId = uniqueId('_product_list_');
    previousState.requestIdRef.current = requestId;

    remove({
      fetchPolicy: 'no-cache',
      context: { useNetworkStatusNotifier: false },
      variables: {
        id: null,
        articleNumbers: [variantToReplace || articleNumber]
      }
    })
    .then(() => {
      add({
        fetchPolicy: 'no-cache',
        context: { useNetworkStatusNotifier: false },
        variables: {
          id: null,
          items: [
            {
              articleNumber: variantArticleNumber || articleNumber,
              quantity,
              description
            }
          ]
        }
      }).then(response => {
        requestId === previousState.requestIdRef.current &&
        syncListFromServer(dispatch, response.data.addToCustomerProductList);
      });
    })
    .catch(err => {
      rollbackList(dispatch, previousState.list);
      console.error('List could not be updated', err);
    });
  };
}

export function removeAction({
                               updateAPI = false,
                               articleNumber,
                               variantArticleNumber,
                               removeMutation
                             }) {
  return (dispatch, getState) => {
    dispatch({
      type: 'REMOVE',
      payload: {
        articleNumber,
        variantArticleNumber
      }
    });

    if (!updateAPI) return;

    const [remove] = removeMutation;
    const previousState = getState();

    const requestId = uniqueId();
    previousState.requestIdRef.current = requestId;

    remove({
      fetchPolicy: 'no-cache',
      context: { useNetworkStatusNotifier: false },
      variables: {
        articleNumbers: [variantArticleNumber || articleNumber],
        id: null
      }
    })
    .then(response => {
      requestId === previousState.requestIdRef.current &&
      syncListFromServer(dispatch, response.data.removeFromCustomerProductList);
    })
    .catch(err => {
      console.error('Product could not be removed from list', err);
      rollbackList(dispatch, previousState.list);
    });
  };
}

export function clearAction({ updateAPI = false, removeMutation }) {
  return (dispatch, getState) => {
    dispatch({ type: 'REPLACE', payload: {} });

    if (!updateAPI) return;

    const [remove] = removeMutation;
    const state = getState();

    const articleNumbers = flattenList(state.list).map(listItem => listItem.articleNumber);

    const requestId = uniqueId();
    state.requestIdRef.current = requestId;

    remove({
      fetchPolicy: 'no-cache',
      context: { useNetworkStatusNotifier: false },
      variables: { articleNumbers, id: null }
    })
    .then(response => {
      requestId === state.requestIdRef.current &&
      syncListFromServer(dispatch, response.data.removeFromCustomerProductList);
    })
    .catch(err => {
      console.error('List could not be cleared', err);
      rollbackList(dispatch, state.list);
    });
  };
}

export function addAction({
                            articleNumber,
                            variantArticleNumber,
                            addMutation,
                            options,
                            updateAPI = false
                          }) {
  return (dispatch, getState) => {
    const { quantity, description } = options;
    dispatch({
      type: 'ADD',
      payload: {
        articleNumber,
        variantArticleNumber,
        options: { quantity, description }
      }
    });

    if (!updateAPI) return;

    const [add] = addMutation;
    const previousState = getState();

    const requestId = uniqueId();
    previousState.requestIdRef.current = requestId;

    add({
      fetchPolicy: 'no-cache',
      context: { useNetworkStatusNotifier: false },
      variables: {
        id: null,
        items: [
          {
            articleNumber: variantArticleNumber || articleNumber,
            quantity,
            description
          }
        ]
      }
    })
    .then(({ data: { addToCustomerProductList } }) => {
      requestId === previousState.requestIdRef.current &&
      syncListFromServer(dispatch, addToCustomerProductList);
    })
    .catch(err => {
      console.error('Product could not be added to list', err);
      rollbackList(dispatch, previousState.list);
    });
  };
}

function syncListFromServer(dispatch, productList) {
  const normalizedList = normalizeServerList(productList);

  dispatch({
    type: 'REPLACE',
    payload: normalizedList
  });
}

function rollbackList(dispatch, productList) {
  dispatch({
    type: 'REPLACE',
    payload: productList
  });
}
import React, { Context, useEffect, useMemo } from 'react';
import { useMutation, useQuery } from 'react-apollo';
import useAuth from '@jetshop/core/components/AuthContext/useAuth';
import { useThunkReducer } from '@jetshop/core/hooks/useThunkReducer';
import { loginAction, refreshAction } from './action-creators';
import { normalizeServerList } from './list-transforms';
import { Action, init, reducer } from './product-list-reducer';

export const ProductListContext = React.createContext(
  undefined
);

// TODO: Provider could take id in order to scope all CRUD to that list id
export const ProductListProvider = function ProductListProvider({ queries, initialState = {}, ...rest }) {
  const { loggedIn } = useAuth();
  const requestIdRef = React.useRef(null);

  const { data: serverList }= useQuery(queries.query, {
    variables: { id: null },
    skip: !loggedIn
  });

  const defaultInitialState = {
    loggedIn,
    requestIdRef,
    list: normalizeServerList(serverList),
    ...initialState
  };

  const [state, dispatch] = useThunkReducer(
    reducer,
      defaultInitialState,
      initialState => init(initialState)
  );

  const usingLocalList = !state.loggedIn;

  usePersistLocalList({ list: state.list });
  useLocalStorageListener({ refresh });

  const [loginMutation] = useMutation(queries.login);

  function refresh() {
    dispatch(refreshAction());
  }

  useEffect(() => {
    if (loggedIn === state.loggedIn) return;

    loggedIn
      ? dispatch(loginAction({ loginMutation }))
      : dispatch({ type: 'LOGOUT' });
  }, [dispatch, loggedIn, loginMutation, state.loggedIn]);

  const value = useMemo(
    () => ({
        state,
      dispatch,
      queries,
      usingLocalList
    }),
    [dispatch, queries, state, usingLocalList]
  );

  // We spread props here so during testing we can override value
  return <ProductListContext.Provider value={value} {...rest} />;
};

/**
 * Persist local list to localStorage on every change
 */
function usePersistLocalList({ list }) {
  useEffect(() => {
    localStorage.setItem('productList', JSON.stringify(list));
  }, [list]);
}

function useLocalStorageListener({ refresh }) {
  useEffect(() => {
    const listener = (e) => {
      if (e.storageArea === localStorage && e.key === 'productList') {
        refresh();
      }
    };

    window.addEventListener('storage', listener);

    return () => {
      window.removeEventListener('storage', listener);
    };
  }, []);
}

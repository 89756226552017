import React, { useEffect, useMemo, useState } from 'react';
import { useMutation } from 'react-apollo';
import {
  addAction,
  clearAction,
  removeAction,
  updateAction
} from './action-creators';
import { flattenList } from './list-transforms';
import { ProductListContext } from './ProductListContext';

export function useProductList() {
  const {
    state: { list },
    dispatch,
    queries,
    usingLocalList
  } = React.useContext(ProductListContext);

  const [hydrated, setHydrated] = useState(!usingLocalList);

  const updateAPI = !usingLocalList;

  const removeMutation= useMutation(queries.remove);
  const addMutation= useMutation(queries.add);

  function toggle(
    articleNumber,
    { variantArticleNumber, quantity, description }
  ) {
    if (inList(articleNumber, { variantArticleNumber })) {
      remove(articleNumber, { variantArticleNumber });
    } else {
      add(articleNumber, {
        variantArticleNumber,
        quantity,
        description
      });
    }
  }

  function remove(
    articleNumber,
    { variantArticleNumber }
  ) {
    dispatch(
      removeAction({
        articleNumber,
        variantArticleNumber,
        removeMutation,
        updateAPI
      })
    );
  }

  function add(
    articleNumber,
    { variantArticleNumber, quantity = 1, description }
  ) {
    dispatch(
      addAction({
        updateAPI,
        articleNumber,
        variantArticleNumber,
        options: { quantity, description },
        addMutation
      })
    );
  }

  function update(
    articleNumber,
    {
      variantArticleNumber,
      quantity,
      description,
      variantToReplace
    }
  ) {
    dispatch(
      updateAction({
        updateAPI,
        articleNumber,
        variantArticleNumber,
        variantToReplace,
        options: { quantity, description },
        removeMutation,
        addMutation
      })
    );
  }

  function clear() {
    dispatch(clearAction({ removeMutation, updateAPI }));
  }

  function inList(
    articleNumber,
    { variantArticleNumber }
  ) {
    if (!hydrated) return false;

    if (articleNumber in list) {
      if (!variantArticleNumber) return true;

      const variantInList = !!list[articleNumber].variants?.[
        variantArticleNumber
        ];

      return variantInList;
    } else {
      return false;
    }
  }

  //  When using localStorage, we only want to display the list values after
  //  hydration, to avoid the initial server and client render being different
  useEffect(() => {
    setHydrated(true);
  }, []);

  const count = useMemo(() => {
    return hydrated ? flattenList(list).length : 0;
  }, [hydrated, list]);

  return { toggle, add, remove, update, clear, inList, list, count };
}

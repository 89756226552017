import { useContext, useMemo, useState, useEffect } from 'react';
import { useQuery } from 'react-apollo';
import {
  flattenList,
  getProductDetailForFlattenedProductList
} from './list-transforms';
import { ProductListContext } from './ProductListContext';

/**
 * Returns a list of products ready to be rendered
 */
export function useProductListItems(id = null, options = {}) {
  if (id && typeof id !== 'string')
    throw new Error('Product list id must be a string or null');

  const {
    state: { list },
    queries,
    usingLocalList
  } = useContext(ProductListContext);

  const [hydrated, setHydrated] = useState(!usingLocalList);

  const flattenedList = useMemo(() => flattenList(list), [list]);

  // Make a `products` query containing every item in the list
  const productQueryResult = useQuery(queries.productsQuery, {
    skip: flattenedList.length === 0,
    variables: {
      articleNumbers: flattenedList
      .map(listItem => listItem.articleNumber)
      .slice(0, options.limit)
    }
  });

  //  When using localStorage, we only want to display the list values after
  //  hydration, to avoid the initial server and client render being different
  useEffect(() => {
    setHydrated(true);
  }, []);

  const result = {
    products: getProductDetailForFlattenedProductList(
      flattenedList,
      productQueryResult
    ),
    loading: hydrated ? productQueryResult.loading : true
  };

  return result;
}
import { useMutation, useQuery } from 'react-apollo';
export { useProductList } from './useProductList';
export { useProductListItems } from './useProductListItems';

export function UpdateListOptions(quantity, description) {
  this.quantity = quantity;
  this.description = description;
}

export function ProductListContextOptions(state, dispatch, usingLocalList, queries) {
  this.state = state;
  this.dispatch = dispatch;
  this.usingLocalList = usingLocalList;
  this.queries = queries;
}

export function ProductListState(loggedIn, list, requestIdRef) {
  this.loggedIn = loggedIn;
  this.list = list;
  this.requestIdRef = requestIdRef;
}

export function ProductListQueries(productsQuery, query, add, login, remove, update) {
  this.productsQuery = productsQuery;
  this.query = query;
  this.add = add;
  this.login = login;
  this.remove = remove;
  this.update = update;
}

export function UpdateListInput(quantity, description, variantArticleNumber) {
  UpdateListOptions.call(this, quantity, description);
  this.variantArticleNumber = variantArticleNumber;
}

export function ProductListDetail(loading, products) {
  this.loading = loading;
  this.products = products;
}

export function NormalizedProductList() {
  this.list = {};
}

export function FlattenedProductListItem(articleNumber, parentArticleNumber, options) {
  this.articleNumber = articleNumber;
  this.parentArticleNumber = parentArticleNumber;
  this.options = options;
}

export function ProductListItemOptions(quantity, description) {
  this.quantity = quantity;
  this.description = description;
}

export function ProductListItemVariants() {
  this.variants = {};
}

export function ProductListProduct(product, quantity, isVariant, variant, validation) {
  Object.assign(this, product);
  this.quantity = quantity;
  this.isVariant = isVariant;
  this.variant = variant;
  this.validation = validation;
}

export function ProductListProductValidation(status, message) {
  this.status = status;
  this.message = message;
}

export function AddToListMutation() {
  return useMutation();
}

export function RemoveFromListMutation() {
  return useMutation();
}

export function UpdateListItemMutation() {
  return useMutation();
}

export function ListProductsQuery() {
  return useQuery();
}

export function ProductsQuery() {
  return useQuery();
}
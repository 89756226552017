import produce from 'immer';
import { validateProduct } from './validate-product';

/**
 * Converts a product list returned from the API to our normalized structure
 * When a base product is selected, it will have a value for `options`.
 * When it has variants, it will have one or more keys for `variants`
 * Either variants or options may be null, but not both.
 */
export function normalizeServerList(
  serverList
){
  const list = {};

  if (!serverList?.customerProductList) return list;

  const items = serverList.customerProductList.items;

  const newList = { ...list };

  for (const item of items) {
    if (item.variant) {
      newList[item.product.articleNumber] = {
        options: newList[item.product.articleNumber]?.options || null,
        variants: produce(
          newList[item.product.articleNumber]?.variants || {},
          draft => {
            draft[item.variant.articleNumber] = {
              options: {
                quantity: item.quantity || 1,
                description: item.description
              },
              parentArticleNumber: item.product.articleNumber
            };
          }
        )
      };
    } else if(item.product) {
      newList[item.product.articleNumber] = {
        variants: newList[item.product.articleNumber]?.variants || null,
        options: {
          quantity: item.quantity ?? 1,
          description: item.description
        }
      };
    }
  }

  return newList;
}

/**
 * Takes a flattened product list and result of a products query and returns
 * product details for each item in the flattened list. */
export function getProductDetailForFlattenedProductList(
  /** May contain base products, variants, or a combination of both. */
  flattenedList,
  /** Contains the results of a products query, which returns only base products */
  productQueryResult
) {
  const { data } = productQueryResult;
  const productsFromQuery = data?.products || [];

  return flattenedList
  .map(listItem => {
    const baseProductArticleNumber =
      listItem.parentArticleNumber || listItem.articleNumber;

    // Find the base product in the query response
    const product = productsFromQuery.find(
      prod => prod.articleNumber === baseProductArticleNumber
    );

    if (!product) return null;

    // Get the variant detail from the product
    const selectedVariant = getVariantFromProductDetails(
      listItem.articleNumber,
      product
    );

    const productListProduct = {
      ...product,
      quantity: listItem.options.quantity,
      isVariant: !!listItem.parentArticleNumber,
      variant: selectedVariant
    };

    return {
      ...productListProduct,
      validation: validateProduct(productListProduct)
    };
  })
  .filter(Boolean);
}

export function getVariantFromProductDetails(
  variant,
  product
) {
  if (!variant) return null;

  const matchedVariant = product.variants.values.find(
    value => value.articleNumber === variant
  );

  return matchedVariant || null;
}

export function flattenList(
  list
) {
  const flattenedList = [];
  const baseProducts = Object.keys(list);

  for (const baseArticleNumber of baseProducts) {
    const variants = list[baseArticleNumber].variants;

    if (variants) {
      const variantArticleNumbers = Object.keys(variants);

      for (const articleNumber of variantArticleNumbers) {
        flattenedList.push({
          articleNumber,
          parentArticleNumber: baseArticleNumber,
          options: variants[articleNumber].options
        });
      }

      if (list[baseArticleNumber].options) {
        flattenedList.push({
          articleNumber: baseArticleNumber,
          options: list[baseArticleNumber].options
        });
      }
    } else {
      flattenedList.push({
        articleNumber: baseArticleNumber,
        options: list[baseArticleNumber].options
      });
    }
  }

  return flattenedList;
}

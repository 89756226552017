import t from '@jetshop/intl';
import React from 'react';
import { css } from 'react-emotion';

import { AddToCart } from './AddToCart';
import { useProductList } from './hooks';
import styled from 'react-emotion';
import theme from '@jetshop/ui/utils/theme';
import { Price } from '../Price';
import Image from '@jetshop/ui/Image';

import ProductLink from '@jetshop/ui/ProductLink'

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingCart, faTimes } from '@fortawesome/pro-light-svg-icons';
library.add(faShoppingCart, faTimes);

const Wrapper = styled('div')`
  display: flex;
  background: white;
  margin-bottom: 0.5rem;
  .stockStatus {
    display: block;
    max-width: 130px;
    text-align: center;
  }
`;

const ProductItemImage = styled('div')`
  flex: 1;
  position: relative;
`;
const StyledImage = styled(Image)`
  background-size: contain;
  padding-top: 100%;
`;

const ProductName = styled('h2')`
  color: black;
  font-size: 1.2rem;
  text-transform: uppercase;
  font-family: ${theme('fontFamilies.body')};
  font-weight: normal;
  ${theme('below.md')} {
    font-size: 13px;
  }
`;
const ProductArtNo = styled('span')`
  display: block;
  color: #a5a5a5;
  font-size: 1rem;
  ${theme('below.md')} {
    display: none;
  }
`;

const ProductDetail = styled('section')`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  flex: 5;
  padding-left: 15px;
`;

const ItemDetails = styled('div')`
  flex: 3;
`;

const LinePrice = styled(Price)`
  .new-price {
  font-weight: 600;
  font-family: ${theme('fontFamilies.heavy')};
  text-align: center;
  color: rgb(206, 50, 51);
  font-size: 1.2rem;
  flex: 2;
  }
  ${theme('below.md')} {
    .new-price {
      font-size: 0.9rem;
    }
    .old-price {
      font-size: 0.7rem;
    }
  }
`;

const RemoveItem = styled('div')`
  text-align: right;
  flex: 1;
  button {
    background: none;
  }
`;

const CartButton = styled('div')`
  flex: 1;
  text-align: right;
  ${theme('below.md')} {
    svg {
      font-size: 0.9rem;
    }
  }
`;
export const CartIcon = styled(FontAwesomeIcon)`
  font-size: 1.8rem;
`;
const CartRemoveIcon = styled(FontAwesomeIcon)`
  font-size: 1.8rem;
  color: #a5a5a5;
  ${theme('below.md')} {
    svg {
      width: 0.425em;
    }
  }
`;

export function Product({ product, loading, children, hideTarget }) {

  const price = {
    price: product.price,
    previousPrice: product.previousPrice
  }

  return (
    <Wrapper>
      <ProductItemImage>
        <ProductLink product={product}>
          {product.images.length > 0 && (
            <StyledImage
              src={product?.images}
              alt={product.name}
              sizes={200}
            />
          )}
        </ProductLink>
      </ProductItemImage>
      <ProductDetail>
        <ItemDetails>
          <ProductName>
            <ProductLink product={product}>
              {product.name}
            </ProductLink>
          </ProductName>
          <ProductArtNo>
            Art.nr: {product.articleNumber}
          </ProductArtNo>
        </ItemDetails>
        <div>
          <LinePrice {...price} />
        </div>
        <CartButton>
          {product.hasVariants || !product?.stockStatus?.buyable
            ? (
              <ProductLink style={{ opacity: '0.5' }} product={product} onClick={hideTarget}>
                <CartIcon icon={['fal', 'shopping-cart']} />
              </ProductLink>
            ) : (
              <AddToCart product={product} />
            )}
        </CartButton>
        <RemoveItem>
          <RemoveFromList articleNumber={product.articleNumber} variant={product.variant}>
            <CartRemoveIcon icon={['fal', 'times']} />
          </RemoveFromList>
        </RemoveItem>
      </ProductDetail>
    </Wrapper>
  );
}

function RemoveFromList({ children, articleNumber, variant }) {
  const { remove } = useProductList();
  const variantArticleNumber = variant?.articleNumber;
  return (
    <button onClick={() => remove(articleNumber, { variantArticleNumber })}>
      {children}
    </button>
  );
}